import React from 'react';
import { LinearProgress } from '@mui/material';
import CommonContents from '../../components/Common-page-layout';

if (window.location.pathname === "/confirm-email") {
  setTimeout(() => {
    window.location.href = "/publication";
  }, 3000);
}

const Content: React.FC = (): JSX.Element => {
  return(
    <>
      <p>
        メールアドレスを登録しています。このまましばらくお待ち下さい。
      </p>
      <div style={{width: "100%", height: "100%"}}>
        <LinearProgress style={{margin: "15% 10%"}}  />
      </div>
    </>
  );
}

const ConfirmEmail: React.FC = (): JSX.Element => {
  return(
    <>
      <CommonContents
      title='メールアドレスの登録'
      subtitle='Email registration'
      children={<Content />}
      />
    </>
  );
}

export default ConfirmEmail;