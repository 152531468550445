import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { middleWidth, mobileWidth, smallPSize, smallWidth, subdomain } from '../../../components/Commmons';

type FetchNewsObj = {
  content_id: number;
  release_date: string;
  tag: string;
  title: string;
}

export const tagReference: any = {
  0: "取得中",
  1: "お知らせ",
  2: "緊急",
  3: "広告",
  100: "エラー"
}

export const NewsTile = styled.ul`
  margin: 0;
  padding: 0;
  margin: 0 20px;
  
  .title {
    text-shadow: none;
    transition: .3s text-shadow;
  }
  
  .line:hover .title{
    text-shadow: 
		0 0 0.10em #2962FF,
		0 0 0.15em #2962FF,
		0 0 0.80em #2962FF,
		0 0 1.00em #2962FF;
    cursor: pointer;
    transition: .3s text-shadow;
  }
`;
  
export const NewsLi = styled.li`
  padding: 20px;
  font-size: 20px;
  text-decoration: none;

  @media (max-width: ${smallWidth}) {
    font-size: ${smallPSize};
  }
  
  .tag {
    display: inline-block;
    text-align: center;
    padding: 10px 50px;
    margin: 0 50px;
    width: 5rem;
    border: 1px solid var(--color-pink);
    border-radius: 10px;
    color: var(--color-light-grey);
    transition: .3s all;

    @media (max-width: ${smallWidth}) {
      padding: 5px 20px;
    }

    @media (max-width: ${mobileWidth}) {
      margin: 0 10px;
      padding: 1px 10px;
    }
  }

  .title {
    @media (max-width: ${middleWidth}) {
      display: block;
      margin: 20px 0 0;
    }

    @media (max-width: ${mobileWidth}) {
      display: block;
      margin: 10px 0 0;
    }
  }
`;

const FetchNews: React.FC<any> = ({ countGet }): JSX.Element => {

  const [pastNews, setPastNews] = useState<FetchNewsObj[]>([{
    content_id: 0,
    release_date: "しばらくお待ち下さい",
    tag: "0",
    title: "",
  }]);
  const [errorStatus, setErrorStatus] = useState<boolean>(false);

  useEffect(() => {      
    axios.post(
      `${subdomain}/fetch-news-list`,
    { count: countGet })
    .then((res: any) => {
      console.log("news-listが来ました");
      if (res.error === "not found") {
        setErrorStatus(true);
        console.log("データの送信に失敗しました。");
        return;
      }
      console.log(res.data);
      setPastNews(res.data);
    })
    .catch(err => {
      console.log("データの送信に失敗しました。");
      setErrorStatus(true);
      console.error(err);
    });
  }, [countGet]);

  return(
    <NewsTile>
      {
        errorStatus ?
        <p>取得できませんでした</p>
        :
        pastNews.map((news, i) =>
          <NewsLi key={i} className="line">
            <span className='date'>{news.release_date}</span>
            <span className='tag'>{tagReference[news.tag]}</span>
            <Link to={"/archives/" + news.content_id} className='title'>{news.title}</Link>
          </NewsLi>
        )
      }
    </NewsTile>
  );
}

export default FetchNews;