import styled from "styled-components";
import { middleWidth, mobileWidth, smallH1Size, smallWidth } from "../Commmons";

export const InputText = styled.input`
  line-height: 1.3;
  font-size: 14px;
  background-color: #fff;
  border-radius: 5px;
  width: 80%;
  display: block;
  margin: 15px 0;
  padding: 8px 8px 8px 32px;
  border: none;

  @media (min-width: ${mobileWidth}) {
    line-height: 1.8;
    
    &:hover {
      border: dashed 2px var(--color-grey);
      padding-left: 30px;
      margin: 13px 0;
    }
  }
`;

export const InputRadio = styled.div`
  .ECM_RadioInput {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 20px 0;
  }

  .ECM_RadioInput-Input {
    opacity: 0;
    width: 0;
    margin: 0;
  }

  .ECM_RadioInput:hover > .ECM_RadioInput-DummyInput{
    border: dashed 2px var(--color-grey);
  }

  .ECM_RadioInput-Input:focus + .ECM_RadioInput-DummyInput {
    background: #fff;
  }

  .ECM_RadioInput-Input:checked + .ECM_RadioInput-DummyInput {
    background: var(--color-grey);
  }

  .ECM_RadioInput-Input:checked + .ECM_RadioInput-DummyInput::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #fff;
  }

  .ECM_RadioInput-DummyInput {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #DDDDDD;
    border: dashed 2px transparent;

    @media (max-width: ${mobileWidth}) {
      width: 25px;
      height: 25px;
    }
  }

  .ECM_RadioInput-LabelText {
    margin-left: 12px;
    display: block;
    font-size: 18px;
    font-weight: bold;

    @media (max-width: ${mobileWidth}) {
      font-size: 15px;
    }
  }
`;

export const SelectStyle = styled.div`
  .select {
    background: #cfcedb;
    height: 40px;
    width: 80%;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    margin: 15px 0;
    
    @media (max-width: ${mobileWidth}) {
      height: 35px;
    }
  }

  .select::after {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    z-index: -1;
  }

  select {
    /* 初期化 */
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: none;
    color: var(--color-grey);
    font-size: 16px;
    width: 100%;
    height: 100%;
    padding: 0 10px;

    &:hover {
      border: dashed 2px var(--color-grey);
      padding: 0 8px;
      cursor: pointer;
    }
  }

  .endSpan {
    margin: 0 10px;
  }
`;

export const ApplicationTransitionStyle = styled.div`
  .form-fadeout-exit {
    opacity: 1;
  }

  .form-fadeout-exit-active {
    opacity: 0;
    transition: all 0.5s;
    transform:translateX(100px);
  }

  .received-data-fadein-enter {
    opacity: 0;
    transform:translateX(50px);
  }

  .received-data-fadein-enter-active {
    opacity: 1;
    transition: all 0.5s;
    transition-delay: 0.8s;
    transform:translateX(0px);
  }
`;

type AreaInt = {
  areaInt?: string;
};

export const FormStyle = styled.div.attrs<AreaInt>(props => ({
areaInt: props.areaInt || "undefined",
}))<AreaInt>`

  h1 {
    @media (max-width: ${middleWidth}) {
      font-size: ${smallH1Size};
    }
  }

  .spin {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }

  .asterisk {
    margin: 0 5px;
    color: yellow;
    font-weight: bold;
  }

  .area {
    height: ${(props) => props.areaInt};
  }

  ul {
    justify-content: space-between;
    display: flex;
    padding: 10px;
    margin: 0;

    @media (max-width: ${smallWidth}) {
      display: block;
    }
  }

  .flexBox {
    width: 100vw;

    @media (max-width: ${smallWidth}) {
      width: 90vw;
    }

    li {
      padding: 0 20px 0;
      display: block;
      list-style-type: decimal;

      @media (max-width: ${mobileWidth}) {
        padding: 0;
      }
    }

    .label {
      position: relative;
      top: 15px;
    }

    .errorMessage {
      position: relative;
      top: 15px;
      float: right;
      color: yellow;
    }
  }

  .flexBox + .flexBox {
    border-left: 1px #fff solid;
    
    @media (max-width: ${smallWidth}) {
      border-left: none;
    }
  }


  .relative {
    width: 100%;
    height: 20rem;
    position: relative;
  }

  #decision {
    width: 100%;
    height: 10rem;
    position: absolute;
    top: 0;
  }
`;