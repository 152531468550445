import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CommonHome from '../CommonHome';

const NewsBoxStyle = styled.div`
  #more {
    margin: 0 20px;
    float: right;
    transition: .3s all;
  }
  
  #more:hover {
    cursor: pointer;
    letter-spacing: .1rem;
    transition: .3s letter-spacing;
  }
`;

const Content: React.FC = (): JSX.Element => {
  const FetchNews = React.lazy(() => import("./Fetch-news"));
  return(
    <NewsBoxStyle>
      <Suspense>
        <FetchNews countGet={"3"}/>
        <Link to="/archives" id='more'>READ MORE</Link>
      </Suspense>
    </NewsBoxStyle>
  );
}

const NewsBox: React.FC = (): JSX.Element => {
  return(
    <CommonHome title='NEWS' children={<Content/>} />
  );
}

export default NewsBox;