import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { commonPSize, ContentsFadeIn, menuLists, middleWidth, smallH1Size, smallPSize, smallWidth } from "./Commmons";
import { ImgAnkerTile } from "./Img-anker-tile";

const GuideLayoutStyle = styled.div`
  margin: 110px 40px 100px;
  padding: 0 50px;

  @media (max-width: ${smallWidth}) {
    padding: 100px 0 0;
    margin: 0;
    line-height: 30px;
  }
  
  h1 {
    text-align: center;
    letter-spacing: 1rem;
    padding-bottom: 20px;
    width: 90%;
    margin: 0 auto;
    border-bottom: 1px solid #fff;
    
    @media (max-width: ${smallWidth}) {
      letter-spacing: normal;
      font-size: ${smallH1Size};
    }
  }
  
  p {
    text-align: center;
    margin: 100px 30px;
    font-size: ${commonPSize};

    @media (max-width: ${smallWidth}) {
      margin: 50px 20px 50px;
      font-size: ${smallPSize};
    }
  }

  #guideTiles {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;

    @media (max-width: ${middleWidth}) {
      justify-content: center;
    }
  }
`;

type GuideLayoutProps = {
  title: string;
  description: string;
  menuListNaviId: number;
}

const GuideLayout: React.FC<GuideLayoutProps> = (props): JSX.Element => {

  const [readEndstatus, setReadEndstatus] = useState<boolean>(false);
  
  useEffect(() => {
    document.title = props.title;
    setReadEndstatus(true);
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [readEndstatus, props.title]);

  return(
    <ContentsFadeIn>
      <CSSTransition
      classNames="readEnd"
      in={readEndstatus}
      timeout={1000}
      unmountOnExit>
        <GuideLayoutStyle>
          <h1>{props.title}</h1>
          <p>{props.description}</p>
          <div id="guideTiles">
            {
              menuLists[props.menuListNaviId].article.map((menuListArticle, i) => 
                <ImgAnkerTile
                to={menuListArticle.url}
                title={menuListArticle.title}
                subTitle={menuListArticle.subtitle}
                imgLink={menuListArticle.image}
                alt={menuListArticle.subtitle}
                key={i}
                />
              )
            }
          </div>
        </GuideLayoutStyle>
      </CSSTransition>
    </ContentsFadeIn>
  );
}

export default GuideLayout;