import React from "react";
import styled from "styled-components";

type ButtonTagProps = {
  onClick?: () => void;
  children?: React.ReactNode;
  width?: string;
  color?: string;
  disabled?: boolean;
  className?: string;
};

type ButtonProps = {
  title: string;
  style?: React.CSSProperties;
} & ButtonTagProps;

export const commonButtonStyle: string = `
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  padding: 10px 30px;
  border-radius: 40px;
  border: 2px solid var(--color-blue);
  cursor: pointer;
  transition: all .3s;

  &:hover {
    opacity: 0.6;
    transition: all .3s;
  }

  &:active {
    opacity: 0.3;
  }
`;

const ButtonStyle = styled.button<ButtonTagProps>`
  ${commonButtonStyle}
  width: ${props => props.width};
  background: ${props => props.color};
`;

export const Button: React.FC<ButtonProps> = (props): JSX.Element => {
  return(
    <ButtonStyle
    style={props.style}
    onClick={props.onClick}
    color={props.color}
    width={props.width}
    disabled={props.disabled}
    className={props.className}
    >
      <span>{props.title}</span>
    </ButtonStyle>
  )
}