import React from 'react';
import CommonContents from '../../components/Common-page-layout';
import { images } from '../../components/commons/images';
import FacultyContentsLayout from '../../components/Faculty-contents';

const Infomatics: React.FC = (): JSX.Element => {
  return(
    <>
      <CommonContents
        title='情報学部'
        subtitle='Faculty of Infomatics'
        children={
          <FacultyContentsLayout
          theme='Filter Bubble'
          signature='オーブリー・コトル'
          image={images.anonymous}
          alt="アノニマス"
          description='Lorem ipsum dolor sit amet. Est laudantium voluptates sit asperiores facere ut dolorum nostrum qui nostrum magnam sed harum quisquam. Ut alias assumenda sed dolores nobis ut expedita rerum ea accusantium ipsam et eveniet veritatis et dolorum sequi eos reprehenderit earum. Qui expedita quia et laboriosam fugiat qui iure cumque quo eligendi inventore.'
        />}
      />
    </>
  );
}

export default Infomatics;