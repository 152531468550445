import access from '../../Images/access.jpg';
import anonymous from '../../Images/anonymous.jpg';
import auditorium from '../../Images/auditorium.jpg';
import bird from '../../Images/bird.jpeg';
import campuslife from '../../Images/campuslife.jpg';
import classroom from '../../Images/classroom.jpg';
import elephant from '../../Images/elephant.jpeg';
import fakeNews from '../../Images/fakenews.png';
import field from '../../Images/field.webp';
import fire from '../../Images/fire.png';
import flower from '../../Images/flower.webp';
import graph from '../../Images/Graph.jpg';
import law from '../../Images/law.jpg';
import library from '../../Images/library.jpg';
import logo from '../../Images/logo.png';
import pcroom from '../../Images/pcroom.jpg';
import people from '../../Images/people.jpg';
import president from '../../Images/president.jpg';
import Yamada from '../../Images/Teizaburo_Yamada.jpeg';
import relux from '../../Images/relux.png';
import understanding from '../../Images/understanding.jpg';
import contact from '../../Images/contact.jpg';
import exam from '../../Images/exam.jpg';
import opencampus from '../../Images/open-campus.jpg';
import work from '../../Images/work.jpg';
import qualification from '../../Images/qualification.jpg';
import memories from '../../Images/memories.jpg';
import infomation from '../../Images/infomation.jpg';
import study from '../../Images/study.jpg';
import note from '../../Images/note.jpg';
import career from '../../Images/career.jpg';
import map from '../../Images/map.jpg';
import chair from '../../Images/chair.jpg';
import wave from '../../Images/wave.png';
import marbles from '../../Images/marbles.jpg';
import searoute from '../../Images/sea-route.jpg';

export const images = {
  access: access,
  anonymous: anonymous,
  auditorium: auditorium,
  bird: bird,
  campuslife: campuslife,
  classroom: classroom,
  elephant: elephant,
  fakeNews: fakeNews,
  field: field,
  fire: fire,
  flower: flower,
  graph: graph,
  law: law,
  library: library,
  logo: logo,
  pcroom: pcroom,
  people: people,
  president: president,
  Yamada: Yamada,
  relux: relux,
  understanding: understanding,
  contact: contact,
  exam: exam,
  opencampus: opencampus,
  work: work,
  qualification: qualification,
  memories: memories,
  infomation: infomation,
  study: study,
  note: note,
  career: career,
  map: map,
  chair: chair,
  wave: wave,
  marbles: marbles,
  searoute: searoute,
}