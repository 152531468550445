import React from "react";
import GuideLayout from "../../components/Guide-layout";

const AdmissionGuide: React.FC = (): JSX.Element=> {
  return(
    <>
      <GuideLayout
      title="受験生の皆様へ"
      description="中ノ鳥島での学びを志望する皆様のための情報を提供します"
      menuListNaviId={2}
      />
    </>
  );
}

export default AdmissionGuide;