import React from "react";
import styled from "styled-components";
import { smallLineHeight, smallPSize, smallWidth } from "../Commmons";


type TableProps = {
  th: string[];
  td: any[];
}

const CommonTable = styled.table`

  margin: 50px auto;
  border-collapse: collapse;
  color: #fff;
  width: 90vw;
  
  @media (max-width: ${smallWidth}) {
    line-height: ${smallLineHeight};
    font-size: ${smallPSize};
    margin: 20px;
  }

  th {
    height: 50px;
  }

  td {
    height: 50px;
    padding: 15px;
    text-align: center;

    @media (max-width: ${smallWidth}) {
      padding: 10px;
    }
  }
  
  th + th, td + td {
    border-left: 2px #fff dotted;
  }
  tbody, tr + tr {
    border-top: 1px #fff solid;
  }
`;

const Table: React.FC<TableProps> = (props): JSX.Element => {

  let tdElements: JSX.Element[] = [];
  let tdObjectKeyCnt: number = 0;
  let trElements: JSX.Element[] = [];

  props.td.forEach((tdObject: any) => {

    // 1つあたりのオブジェクトkeyの個数を数える
    tdObjectKeyCnt = Object.keys(tdObject).length;
    console.log(tdObjectKeyCnt);
  
    // td の配列を作成
    for (let i = 1; i <= tdObjectKeyCnt; i++) {
      tdElements.push(
        <td>{tdObject[`td_${i}`]}</td>
      );
    }
    // trに内包させる
    trElements.push(
      <tr>
        {
          tdElements.map((tdElemnt, i) => 
            <React.Fragment key={i}>
              {tdElemnt}
            </React.Fragment>
          )
        }
      </tr>
    );
    console.log(tdElements);
    // リセット
    tdElements = [];
  });
  
  return(
    <CommonTable>
      <thead>
        <tr>
          {
            props.th.map((th, i) => 
              <th key={i}>{th}</th>
            )
          }
        </tr>
      </thead>
      <tbody>
        {
          trElements.map((trElement, i) => 
            <React.Fragment key={i}>
              {trElement}
            </React.Fragment>
          )
        }
      </tbody>
    </CommonTable>
  );
}

export default Table;