import React from 'react';
import CommonContents from '../../components/Common-page-layout';
import { images } from '../../components/commons/images';
import FacultyContentsLayout from '../../components/Faculty-contents';

const Law: React.FC = (): JSX.Element => {
  return(
    <>
      <CommonContents
        title='法学部'
        subtitle='Faculty of Law'
        children={
          <FacultyContentsLayout
          theme='Do it NETSUZO'
          signature='ゴーン'
          image={images.law}
          alt="天秤"
          description='本学部では、役員報酬を過少に記載した有価証券報告書を提出する方法、東京地検特捜部に逮捕された後の東京拘置所での過ごし方、多額の保釈金を払い拘置所から保釈されるときの変装の仕方、元軍人の協力による関西国際空港からレバノン・ベイルート国際空港へ逃亡する際の楽器ケースでの過ごし方といった実践的な学びを提供します。法学の枠を圧倒的に超越した原理・原則を学び、様々な問題を解決する能力を身に着けたより良い人材を育成します。'
        />}
      />
    </>
  );
}

export default Law;