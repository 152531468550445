import React from "react";
import GuideLayout from "../../components/Guide-layout";

const AccessGuide: React.FC = (): JSX.Element=> {
  return(
    <>
      <GuideLayout
      title="アクセス"
      description="中ノ鳥島へのルート"
      menuListNaviId={4}
      />
    </>
  );
}

export default AccessGuide;