import React, { memo, useEffect, useRef } from 'react';
import movie from '../../video/pexels-rostislav-uzunov-7385122.mp4';
import styled from "styled-components";
import { MotionTile } from './MotionTile'
import { Parallax } from 'react-scroll-parallax';
import { mobileWidth, smallWidth } from '../../components/Commmons';
import useResizeObserver from "use-resize-observer";
import { images } from '../../components/commons/images';


const MemoMotionTile = memo(MotionTile);

const Container = styled.div`
  height: 600px;
  
  #header {
    position: relative;
  }

  #passing {
    width: 100%;
    height: 100%;
  }

  #stringPosition {
    position: absolute;
    top: 15vw;
    left: 7%;
    font-size: 7vw;

    @media (max-width: ${mobileWidth}) {
      top: 200px;
    }
  }

  .moji {
    margin: 20px;
    text-shadow: 0px 0px 5px #fff,
    0px 0px 20px #326693,
    0px 0px 40px #326693,
    0px 0px 60px #31a9ee,
    0px 0px 80px #31a9ee,
    0px 0px 100px #31a9ee;
  }

  .goukaku {
    text-shadow: 0px 0px 5px #fff ,
    0px 0px 20px #00FF00,
    0px 0px 40px #00FF00,
    0px 0px 60px #00FF00,
    0px 0px 80px #CCFFCC,
    0px 0px 100px #CCFFCC;
  }

  .weight {
    @media (max-width: ${smallWidth}) {
      font-weight: bold;
    }
  }

  .wave {
    z-index: -1000;
    position: relative;
  }
`;

const Publication: React.FC = (): JSX.Element => {
  const contentWidth = useRef<any>(null);
  const { width } = useResizeObserver({ref: contentWidth});

  useEffect(() => {
    document.title = "おめでとうございます"
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return(
    <>
      <Container ref={contentWidth}>
        <Parallax speed={-40} disabled={width <= 950 ? true : false}>
          <div id='header'>
            <div id='passing'>
              {
                width > 950 ?
                <video className='wave' width={"100%"} autoPlay loop muted>
                  <source src={movie} type="video/mp4" />
                </video>
                :
                <div className='wave'>
                  <img src={images.wave} width={"100%"} height={"600px"} alt="波" />
                </div>
              }
            </div>
            <div id='stringPosition'>
              <p className='moji weight'>ようこそ 根津増大学 へ</p>
              <p className='moji weight'>　あなたは <span className='goukaku'>合格</span> しました</p>
            </div>
          </div>
        </Parallax>
      </Container>
      <Parallax speed={40} disabled={width <= 950 ? true : false}>
        <MemoMotionTile/>
      </Parallax>
    </>
  );
}

export default Publication;