import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { CSSTransition } from "react-transition-group";
import { getCookieValue, subdomain } from '../../../components/Commmons';
import { LinearIndeterminate } from '../../../components/Material-ui';
import { ConfirmResult } from './Result';
import { ApplicationTransitionStyle, FormStyle, InputText, SelectStyle } from '../../../components/commons/form-styles';
import axios from 'axios';
import { Button } from '../../../components/commons/Button';


type ConfirmResData = {
  examNum: number;
  name: string;
  birthday: number;
  email: string;
};

type ConfirmReqData = {
  selectYear: number;
  selectMonth: number;
  selectDate: number;
} & ConfirmResData;

type AddTokenConfirmReqData = {
  token: any;
} & ConfirmReqData;

const checkInputValue = (reqData: ConfirmReqData, resData: ConfirmResData): boolean => {

  console.log("reqData", reqData);
  console.log("resData", resData);
  
  // 入力された誕生日と取得した誕生日の相違を調べる
  const reqSelectYear = reqData.selectYear;
  const reqSelectMonth = reqData.selectMonth;
  const reqSelectDate = reqData.selectDate;
  const resBirthday = resData.birthday;
 // 送られてきた日付を日本時間に修正
  const fetchResBirthday: Date = new Date(resBirthday);
  const mergeResBirthday = `${fetchResBirthday.getFullYear()}-${fetchResBirthday.getMonth() + 1}-${fetchResBirthday.getDate()}`;
  const reqBirthday = `${reqSelectYear}-${reqSelectMonth}-${reqSelectDate}`;

  // 入力された名前と取得した名前の相違を調べる
  const reqName = reqData.name;
  const resName = resData.name;

  // 入力されたメールと取得したメールの相違を調べる
  const reqMail = reqData.email;
  const resMail = resData.email;

  if (reqBirthday !== mergeResBirthday || reqName !== resName || reqMail !== resMail) {
    console.log("違う！");
    return true;
  }

  return false;
};


const Confirm: React.FC = (): JSX.Element => {
  const { register, handleSubmit, formState: { errors } } = useForm<ConfirmReqData>();
  const [confirmStatus, setConfirmStatus] = useState<boolean>(false);
  const [confirmErrorStatus, setConfirmErrorStatus] = useState<boolean>(false);
  const [notFoundStatus, setNotFoundStatus] = useState<boolean>(false);
  const [redirectPermit, setRedirectPermit] = useState<boolean>(false);
  const [spinStatus, setSpinStatus] = useState<boolean>(false);

  const onSubmit: SubmitHandler<ConfirmReqData> = (inputValue): any => {
    console.log(inputValue);
    console.log(document.cookie);
    setSpinStatus(true);
    const addTokenReqData: AddTokenConfirmReqData = {
      token: getCookieValue("token"),
      ...inputValue
    }

    axios.post(
      `${subdomain}/confirm`,
      addTokenReqData,
      { withCredentials: true }
    )
    .then((res: any) => {
      setConfirmStatus(true);
      if (res.data.result === "not found" || checkInputValue(inputValue, res.data as ConfirmResData)) {
        setNotFoundStatus(true);
        console.log("該当データは存在しません");
        console.log(res.data.result);
        return;
      }
      console.log(res);
      console.log("出願済み");
    })
    .catch(err => {
      console.error(err);
      setTimeout(() => {
        console.log("データの送信に失敗しました。");
        setConfirmStatus(true);
        setConfirmErrorStatus(true);
      }, 5000);
    });
  };

  const redirectPublication = (): void => {
    setRedirectPermit(true);
    setTimeout(() => {
      setRedirectPermit(false);
      window.location.href = "/publication";
    }, 3000);
  }

  return (
    <ApplicationTransitionStyle>
      <FormStyle areaInt={"100%"}>
        <h1>出願状況確認</h1>
        <div className='area'>
          <CSSTransition
          classNames="form-fadeout"
          in={!confirmStatus}
          timeout={700}
          unmountOnExit>      
            <form onSubmit={handleSubmit(onSubmit)}>
              <p>
                ＊印の項目は、必須入力です。
                出願時にメールアドレスを入力された方は、必ず入力してください。
              </p>
              <ul>
                <div className='flexBox'>
                  <li>
                    <span className='label'>受験番号<span className='asterisk'>＊</span>:</span>
                    {errors?.examNum?.message === "" && <span className="errorMessage">未入力です</span>}
                    <InputText type="text" pattern="^[0-9]+$" placeholder="半角数字" {...register("examNum", {required: true})} autoFocus />
                  </li>

                  <li>
                    <span className='label'>受験者氏名<span className='asterisk'>＊</span>:</span>
                    {errors?.name?.message === "" && <span className="errorMessage">未入力です</span>}
                    <InputText type="text" placeholder="" maxLength={50} pattern="[^\x20-\x7E]*" {...register("name", {required: true})} />
                  </li>

                  <li>
                    <span className='label'>メールアドレス:</span>
                    <InputText type="email" placeholder="" {...register("email")} />
                  </li>
                </div>
                <div className='flexBox'>
                  <li>
                    <span className='label'>生年月日<span className='asterisk'>＊</span>:</span>
                    <SelectStyle>
                      <select {...register("selectYear")} className="select">
                        {(() => {
                          const yearsArray: any[] = [];
                          const possibleYear: any = new Date().getFullYear() - 16;

                          for (let i: number = 1900; i < possibleYear; i++) {
                            yearsArray.unshift(<option key={i}>{i}</option>);
                          }
                          return yearsArray;
                        })()}
                      </select><span className='endSpan'>年</span>
                      <select {...register("selectMonth")} className="select">
                        {(() => {
                            const monthArray: any[] = [];
                            for (let i: number = 1; i <= 12; i++) {
                              monthArray.push(<option key={i}>{i}</option>);
                            }
                            return monthArray;
                        })()}
                      </select><span className='endSpan'>月</span>
                      <select {...register("selectDate")} className="select">
                        {(() => {
                            const daysArray: any[] = [];
                            for (let i: number = 1; i <= 31; i++) {
                              daysArray.push(<option key={i}>{i}</option>);
                            }
                            return daysArray;
                        })()}
                      </select><span className='endSpan'>日</span>
                    </SelectStyle>
                  </li>

                </div>
              </ul>
              <div className='spin'>
                { spinStatus && LinearIndeterminate(({})) }
              </div>
              <Button
              style={{display: "block", margin: "50px auto"}}
              title="確認"
              color="#7B56E9"
              width="60%"
              disabled={spinStatus}
              />
            </form>
          </CSSTransition>
          <CSSTransition
          classNames="received-data-fadein"
          in={confirmStatus}
          timeout={3000}
          unmountOnExit>
            <ConfirmResult
            confirmErrorStatus={confirmErrorStatus}
            notFoundStatus={notFoundStatus}
            redirectPublication={redirectPublication}
            redirectPermit={redirectPermit}
            LinearIndeterminate={LinearIndeterminate}
            />
          </CSSTransition>
        </div>
      </FormStyle>
    </ApplicationTransitionStyle>
  );
}

export default Confirm;