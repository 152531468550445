import React from "react";
import { Link, To } from "react-router-dom";
import styled from "styled-components";
import { smallWidth } from "./Commmons";

const MoveLinkImage = styled.div`
  height: 350px;
  margin: 0 0 30px;

  li {
    width: 350px;
    height: 300px;
    margin: 0 0 0px;
    border-radius: 30% 10%;
    overflow: hidden;
    /* 下記はSafariでのレイアウト崩れを防ぐ処置 */
    will-change: transform;
    
    @media (max-width: ${smallWidth}) {
      width: 100vw;
      border-radius: 10px;
    }
  }

  #imgLink {
    width: 100%;
    height: 100%;
    background: var(--color-background);
    position: relative;
    z-index: 20;
    transition: transform 1s, opacity 1s;
  }

  img {
    width: 100%;
    height: auto;
    
    @media (min-width: ${smallWidth}) {
      height: 100%;
      filter: blur(2px);
      transition: filter 1s;
    }
  }
  
  #imgLink:hover {
    transform: scale(1.3,1.3);
    opacity: 0.8;
    transition: all 1s;

    @media (max-width: ${smallWidth}) {
      transform: none;
    }
  }

  #imgLink:hover img {
    filter: blur(0px);
    transition: filter 1s;
  }
  
  #wrapper {
    width: 80%;
    height: 40%;
    padding: 10px;
    background: #555;
    opacity: 0.8;
    z-index: 30;
    position: absolute;
    right: 0;
    bottom: 20%;
    transition: all .5s;

    #subWrapper {
      position: relative;
      width: 100%;
      height: 100%;

      .articleTitle {
        margin: 0;
        position: absolute;
        text-align: left;
        bottom: 0;
        color: #efefef;
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-weight: bold;
        letter-spacing: .9rem;
      }
      
      .articleSubTitle {
        margin: 0;
        position: absolute;
        top: 0;
        color: var(--color-grey);
        font-size: 2rem;
      }
    }
  }

  @media (min-width: ${smallWidth}) {
    #imgLink:hover #wrapper {
      opacity: 0;
      transform: translateY(200px);
      transition: all .5s;
    }
  }

  .fadeTitle {
    font-size: 1.5rem;
    font-weight: bold;
    position: absolute;
    opacity: 0;
    transform: translateY(-30px);
    transition: all .5s;
    
    &::before {
      content: "＞ ";
    }
  }
  
  @media (min-width: ${smallWidth}) {
    &:hover .fadeTitle {
      opacity: 1;
      transform: translateY(0px) translateX(80px);
      transition: all .5s;
    }
  }
`;

type ImgAnkerTileProps = {
  to: To;
  title: string;
  subTitle: string;
  imgLink: string;
  alt: string | undefined;
}


export const ImgAnkerTile: React.FC<ImgAnkerTileProps> = (props): JSX.Element => {  
  return(
    <MoveLinkImage>
      <li>
        <div id="imgLink">
          <Link to={props.to}>
            <img src={props.imgLink} alt={props.alt} />
            <div id="wrapper">
              <div id="subWrapper">
                <p className="articleTitle">{props.title}</p>
                <p className="articleSubTitle">{props.subTitle}</p>
              </div>
            </div>
          </Link>
        </div>
      </li>
      <Link to={props.to} className="fadeTitle">{props.title}</Link>
    </MoveLinkImage>
  );
}