import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NoMatch from "./No-match";
import Home from './routes/home/Home';
import Archives from './routes/home/news/Archives';
import News from './routes/home/news/News';
import UnivGuide from './routes/Information/Univ-guide';
import Introduction from './routes/Information/Introduction';
import History from './routes/Information/History';
import Message from './routes/Information/Message';
import FacultyGuide from './routes/faculty/Faculty-guide'
import Law from './routes/faculty/Law'
import Economics from './routes/faculty/Economics';
import Infomatics from './routes/faculty/Infomatics';
import AdmissionGuide from './routes/admissions/Admission-guide';
import OpenCampus from './routes/admissions/Open-campus';
import Exam from './routes/admissions/Exam';
import Contact  from './routes/admissions/Contact';
import CareerGuide from './routes/employment/Career-guide';
import Career from './routes/employment/Career';
import Qualification from './routes/employment/Qualification';
import Memories from './routes/employment/Memories';
import AccessGuide from './routes/accessMap/Access-guide';
import Map from './routes/accessMap/Map';
import Publication from './routes/publication/Publication';
import ConfirmEmail from './routes/publication/Confirm-email';

const App: React.FC = (): JSX.Element => {
  return(
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/archives" element={<Archives />} />
      <Route path="archives/:contentId" element={<News />} />
      <Route path="/univ-guide" element={<UnivGuide />} />
      <Route path="/introduction" element={<Introduction />} />
      <Route path="/history" element={<History />} />
      <Route path="/message" element={<Message />} />
      <Route path="/faculty-guide" element={<FacultyGuide />} />
      <Route path="/law" element={<Law />} />
      <Route path="/economics" element={<Economics />} />
      <Route path="/infomatics" element={<Infomatics />} />
      <Route path="/admission-guide" element={<AdmissionGuide />} />
      <Route path="/oc" element={<OpenCampus />} />
      <Route path="/exam" element={<Exam />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/career-guide" element={<CareerGuide />} />
      <Route path="/career" element={<Career />} />
      <Route path="/qualification" element={<Qualification />} />
      <Route path="/memories" element={<Memories />} />
      <Route path="/access-guide" element={<AccessGuide />} />
      <Route path="/map" element={<Map />} />
      <Route path="/publication" element={<Publication />} />
      <Route path="/confirm-email" element={<ConfirmEmail />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default App;