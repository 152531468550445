import React from 'react';
import CommonContents from '../../components/Common-page-layout';

const Content: React.FC = (): JSX.Element => {
  return(
    <>
      <p>学士</p>
    </>
  );
}

const Qualification: React.FC = (): JSX.Element => {
  return(
    <>
      <CommonContents
      title='資格'
      subtitle='Qualification'
      children={<Content />}
      />
    </>
  );
}

export default Qualification;