import React from "react";
import GuideLayout from "../../components/Guide-layout";

const UnivGuide: React.FC = (): JSX.Element=> {
  return(
    <>
      <GuideLayout
      title="大学案内"
      description="歴史ある本学の由緒と学長から皆様に向けた熱いメッセージ"
      menuListNaviId={0}
      />
    </>
  );
}
export default UnivGuide;