import React, { memo } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { middleWidth, OverlayPlate, OverlayTransitionStyle } from "../../../components/Commmons";
import { ModalContent } from "./Modal-content";


const MemoApplication = memo(ModalContent);

const ModaltransitionStyle = styled.div`
  .modal-wrapper{
    z-index: 100;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    .modal-enter {
      opacity: 0;
      transform: scale(0.8);
    }
    
    .modal-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: all 0.5s;
    }

    .modal-exit {
      opacity: 1;
    }

    .modal-exit-active {
      opacity: 0;
      transition: all 0.5s;
      transform: scale(0.8);
    }
  }
`;

const ModalStyle = styled.div`
  padding: 20px 10px 80px 10px;
  width: 70rem;
  height: 40rem;
  border-radius: 10px;
  background-color: #8A1C25;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (max-width: ${middleWidth}) {
    width: 100vw;
    height: 80vh;
    overflow-y: scroll;
  }
  
  .content{
    width: 90%;
  }
`;

const Modal: React.FC<any> = ({showApplication,setShowApplication}): JSX.Element => {
  return(
    <>
      <ModaltransitionStyle>
        <div className="modal-wrapper">
          <CSSTransition classNames="modal" in={showApplication} timeout={500} unmountOnExit>
            <ModalStyle>
              <div className="content">
                <MemoApplication setShowApplication={setShowApplication} />
              </div>
            </ModalStyle>
          </CSSTransition>
        </div>
      </ModaltransitionStyle>
      <OverlayTransitionStyle>
        <CSSTransition classNames="overlay" in={showApplication} timeout={500} unmountOnExit>
          <OverlayPlate onClick={() => setShowApplication((prev: boolean) => !prev)} />
        </CSSTransition>
      </OverlayTransitionStyle>
    </>
  );
}

export default Modal;