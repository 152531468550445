import React from "react";
import styled from "styled-components";
import { middleWidth, mobileWidth, smallH1Size, smallPSize, smallWidth } from "./Commmons";


const FacultyDescription = styled.div`
  display: flex;
  
  @media (max-width: ${mobileWidth}) {
    display: contents;
    margin: 100px;
  }

  #block {
    width: 90%;
    margin: 0;
    padding: 20px;
  }

  #signatureTile {
    position: relative;

    @media (max-width: ${middleWidth}) {
      width: 100%;
    }

    .facultyTheme {
      width: 70%;
      height: 30%;
      padding: 20px 30px;
      font-weight: bold;
      background: rgba(0, 0, 0, 0.8);
      position: absolute;
      bottom: 30%;
      left: 0;
    }

    img {
      width: 100%;
      height: 90%;
    }

    #center {
      height: 100%;
      margin: auto 0;
      position: relative;
    }

    #theme {
      text-align: center;
      font-size: 1.9rem;
      position: absolute;
      top: 30%;
      line-height: 1.9rem;
      margin: 0;
      width: 100%;

      @media (max-width: ${middleWidth}) {
        font-size: ${smallH1Size};
      }
      
      @media (max-width: ${mobileWidth}) {
        top: calc(50% - ${smallH1Size});
      }
    }
    
    #signature {
      display: block;
      position: absolute;
      bottom: 10px;
      right: 0;
      text-align: right;
      font-size: 1rem;
      margin-top: 4vw;
      width: 100%;

      @media (max-width: ${smallWidth}) {
          font-size: ${smallPSize};
      }
      
      @media (max-width: ${mobileWidth}) {
        font-size: .7rem;
        top: 10vw;
      }
    
      &::before{
        content: 'ー　学部長 ';
      }
    }
  }
`;

type FacultyContentsProps = {
  theme: string;
  signature: string;
  image: any;
  alt: string;
  description: string;
}

const FacultyContentsLayout: React.FC<FacultyContentsProps> = (props): JSX.Element => {
  return(
    <FacultyDescription>
    <div id='signatureTile'>
      <div className='facultyTheme'>
        <div id='center'>
            <p id='theme'>{props.theme}</p>
          <span id='signature'>{props.signature}</span>
        </div>
      </div>
      <img src={props.image} alt={props.alt} />
    </div>
    <div id='block'>
      <p>{props.description}</p>
    </div>
  </FacultyDescription>
  );
}

export default FacultyContentsLayout;
