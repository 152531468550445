import React, { useState, useEffect, memo, SetStateAction } from 'react';
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { Register } from "./Register";
import Confirm from "./Confirm"
import { middleWidth, mobileWidth, smallH1Size, smallPSize, smallWidth, subdomain } from '../../../components/Commmons';
import axios from 'axios';
import { icons } from '../../../components/commons/icons';
import { Button, commonButtonStyle } from '../../../components/commons/Button';


const MemoConfirm = memo(Confirm);
const MemoRegister = memo(Register);

type CustomProps = {
  active?: boolean | undefined;
  backgroundColor?: string;
};

const ApplicationModal = styled.div`
  position: relative;

  h1 {
    padding: 30px;
    border-bottom: 1px solid #fff;

    @media (max-width: ${middleWidth}) {
      font-size: ${smallH1Size};
      margin: 0;
      padding: 20px;
    }
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 30px;

    @media (max-width: ${mobileWidth}) {
      display: none;
    }
  }

  #closeCrossButton {
    @media (min-width: ${mobileWidth}) {
      display: none;
    }

    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 10px;
    
    img {
      padding: 5px;
      background: #efefef;
      border-radius: 50%;
      transition: opacity .3s;

      &:active {
        opacity: .5;
      }
    }
  }

  p{
    margin: 20px;
    font-size: 1.3em;
    font-weight: bold;
    
    @media (max-width: ${smallWidth}) {
      font-size: ${smallPSize};
    }
  }
`;

const ButtonBox = styled.div<CustomProps>`
  // ボタンクリック後に以下の処理を行わないと要素が存在し続く
  display: ${props => (props.active ? "none" : "flex")};
  justify-content: space-evenly;
  margin: 100px 0;
  
  @media (max-width: ${middleWidth}) {
    display: ${props => (props.active ? "none" : "block")};
    margin: 0;
  }

  .theme {
    font-size: 2em;
    
    @media (max-width: ${middleWidth}) {
      font-size: 1.5em;
    }
  }

  .explane {
    font-size: 1em;
    margin: 30px 10px;
    line-height: 30px;

    @media (max-width: ${middleWidth}) {
      margin: 15px 0;
      font-size: .9rem;
      line-height: 20px;
    }
  }

  .emphasis {
    padding: 10px 30px;
    margin: 10px 0;
    border-radius: 40px;

    @media (max-width: ${mobileWidth}) {
      padding: 5px 15px;
    }
  }

`;

export const FormTransitionStyle = styled.div`
  .form-fadein-enter {
    opacity: 0;
    transform: scale(0.8);
  }

  .form-fadein-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.5s, transform 0.5s;
  }
`;

const ApplicationButton = styled.button<CustomProps>`
  display: ${props => (props.active ? "none" : "content")};
  ${commonButtonStyle}
  padding: 30px;
  background: ${props => props.backgroundColor};
  width: 40%;
  
  @media (max-width: ${middleWidth}) {
    display: ${props => (props.active ? "none" : "block")};
    width: 60%;
    height: 30vh;
    padding: 20px;
    margin: 10px auto;
  }

  @media (max-width: ${mobileWidth}) {
    width: 100%;
    height: 30vh;
  }
`;

type CloseStatus = {
  setShowApplication: React.Dispatch<SetStateAction<boolean>>;
}

export const ModalContent: React.FC<CloseStatus> = ({setShowApplication}): JSX.Element => {

  const [apllicationStatus, setApllicationStatus] = useState<boolean>(false);
  const [confirmStatus, setConfirmStatus] = useState<boolean>(false);

  useEffect(() => {
    axios.get(`${subdomain}/token`, {
      withCredentials: true })
    .then(res => {
      console.log("トークン読込");
      console.log(res);

      if (!res.data.update) {
        return console.log("発行済");
      }

      document.cookie = `token=${res.data.token};max-age=${res.data.maxAge / 1000}`;
    })
    .catch(err => {
      console.log(err);
    })
  }, [apllicationStatus]);

  const closeModal = () => {
    setShowApplication(false);
  }

  return(
    <ApplicationModal>
      <h1 style={{display: apllicationStatus || confirmStatus ? "none" : "content"}}>
      出願・出願状況の確認
      </h1>
      <Button
      onClick={closeModal}
      title="閉じる"
      width="150px"
      color="var(--color-blue)"
      className="closeButton"
      />
      <div id="closeCrossButton">
        <img src={icons.cross} alt="閉じる" width={"30px"} onClick={closeModal}/>
      </div>
      <p style={{display: apllicationStatus || confirmStatus ? "none" : "content"}}>
        以下のボタンより行う操作を指定してください
      </p>

      <ButtonBox active={apllicationStatus || confirmStatus}>
        <ApplicationButton
        className='applicationButton'
        backgroundColor='#F14646'
        onClick={() => setApllicationStatus((prev) => !prev)}
        active={apllicationStatus || confirmStatus}
        >
          <span className='theme'>出願をする</span>
          <p className='explane'>出願情報を入力します。出願で知り得た個人情報は、受験票の発行のみに使用します。</p>
          <span className='emphasis' style={{background: '#F27272'}}>出願ページへ</span>
        </ApplicationButton>

        <ApplicationButton
        className='applicationButton'
        backgroundColor='#7B56E9'
        onClick={() => setConfirmStatus((prev) => !prev)}
        active={confirmStatus || apllicationStatus}
        >
        <span className='theme'>出願状況の確認</span>
        <p className='explane'>出願状況を確認します。確認に際し、発行された受験番号をご用意ください。</p>
        <span className='emphasis' style={{background: '#A28BE8'}}>出願確認ページへ</span>
        </ApplicationButton>
      </ButtonBox>

      <FormTransitionStyle>
        <CSSTransition
        classNames="form-fadein"
        in={apllicationStatus}
        timeout={500}
        unmountOnExit>
          <MemoRegister />
        </CSSTransition>
        
        <CSSTransition
        classNames="form-fadein"
        in={confirmStatus}
        timeout={500}
        unmountOnExit>
          <MemoConfirm />
        </CSSTransition>
      </FormTransitionStyle>
    </ApplicationModal>
  );
}