import React from 'react';
import CommonContents from '../../components/Common-page-layout';
import Table from '../../components/commons/Table';


const schoolHistories: { td_1: number, td_2: string }[] = [
  {
    td_1: 1907,
    td_2: '山田禎三郎により小笠原地方に俗称「ガンジス島」を発見する。'
  },
  {
    td_1: 1908,
    td_2: '政府の閣議決定により「中ノ鳥島」と名付ｆけられ、日本領に編入される。'
  },
  {
    td_1: 1910,
    td_2: '４月、発見者の山田禎三郎がこの地に大学を作ることを政府に嘆願する。'
  },
  {
    td_1: 1910,
    td_2: '６月、帝国大学令によって政府が中ノ鳥島に大学開設を告示する。また、初代学長を山田禎三郎と決定した。'
  },
  {
    td_1: 1915,
    td_2: '大学開設。名称を「中ノ鳥島帝国大学」とする。法学部、経済学部を設置。'
  },
  {
    td_1: 1930,
    td_2: '山田禎三郎初代学長が死去。'
  },
  {
    td_1: 1945,
    td_2: '校舎を戦災によって殆どを焼失する。'
  },
  {
    td_1: 1946,
    td_2: '校舎を再建する。'
  },
  {
    td_1: 1947,
    td_2: '帝国大学令が国立総合大学令と改題され「根津増大学」に改称する。'
  },
  {
    td_1: 1960,
    td_2: '情報学部を設置。'
  },
];

const Content: React.FC = (): JSX.Element => {
  return(
    <>
      <Table th={["年", "概要"]} td
      ={schoolHistories} />
    </>
  );
}

const History: React.FC = (): JSX.Element => {
  return(
    <>
      <CommonContents
      title='本学の歴史'
      subtitle='History'
      children={<Content />}
      />
    </>
  );
}

export default History;