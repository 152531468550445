import React from 'react';
import styled from "styled-components";
import { naviLists, smallWidth } from '../../components/Commmons';
import { ImgAnkerTile } from '../../components/Img-anker-tile';
import CommonHome from './CommonHome';

const ContentGuide = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  
  @media (max-width: ${smallWidth}) {
    flex-direction: column;
  }
`;

const Content: React.FC = (): JSX.Element => {

  const guideComponents: JSX.Element[] = [];
  // 全てのguideComponentを取得して配列に入れる
  naviLists.forEach(naviList => {
    return guideComponents.push(
      <ImgAnkerTile
      to={naviList.url}
      title={naviList.title}
      subTitle={naviList.subtitle}
      imgLink={naviList.image}
      alt={naviList.title}
      />
    );
  });

  return(
    <ContentGuide>
      {
        guideComponents.map((guideComponent, i) => {
          return(
            <div key={i}>
              {guideComponent}
            </div>
          );
        })
      }
    </ContentGuide>
  );
}


const Contents: React.FC = (): JSX.Element => {
  return(
    <CommonHome title='Contents' children={<Content/>} />
  );
}

export default Contents;