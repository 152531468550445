import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { subdomain } from "../../../components/Commmons";
import CommonContents from "../../../components/Common-page-layout";
import { tagReference } from "./Fetch-news";

type NewsContent = {
  release_date: string;
  tag: string;
  title: string;
  content: any;
}

const News: React.FC = (): JSX.Element => {

  const [newsContent, setNewsContent] = useState<NewsContent>({
    release_date: "",
    tag: "100",
    title: "ページを取得できません",
    content: ["サーバーに問題が発生し、ページを表示できません。"]
  });
  const { contentId } = useParams();

  useEffect(() => {
    axios.post<any>(
      `${subdomain}/fetch-news-content`,
      { contentId })
    .then((res: any) => {
      let resData: NewsContent = res.data;

      // 改行地点を作る
      let contentArray: string[] = resData.content.split("\n");

      resData.content = contentArray;
      console.log(resData);
      setNewsContent(resData);
      document.title = res.data.title;
    })
    .catch(err => {
      console.error(err);      
    });
  }, []);

  return(
    <>
      <CommonContents
      title={
        newsContent?.title + "【" + tagReference[newsContent.tag] + "】"
      }
      subtitle="NEWS"
      children={
        <>
          {
            newsContent?.content.map((contentElement: string) =>
            <p>{contentElement}</p>
            )
          }

          {
            newsContent?.tag === "100" ? <></>: <p style={{textAlign: "right"}}>{newsContent?.release_date} 公開</p>
          }
        </>
      }/>
    </>
  );
}

export default News;