import React from 'react';
import { currentYear, mobileWidth, smallPSize, smallWidth } from '../../components/Commmons';
import styled from 'styled-components';
import CommonContents from '../../components/Common-page-layout';
import { images } from '../../components/commons/images';
import { icons } from '../../components/commons/icons';

const PhilosophyContent = styled.div`

  #flex {
    display: flex;

    @media (max-width: ${smallWidth}) {
      display: contents;
    }
  }

  #centerPhilosophy {
    margin: 100px 0;
    width: 100%;
    font-size: 5rem;
    text-align: center;
    line-height: 0.95em;
    font-weight: bold;
    letter-spacing: 5rem;
    text-indent: 5rem;
  	color: #fffff0;
	  text-shadow:
		0 0.05em 0.08em #d0d0d0b1;

    @media (max-width: ${smallWidth}) {
      display: none;
    }
  }

  #classroom {
    width: 150vw;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    @media (max-width: ${smallWidth}) {
      width: 100%;
    }
  }

  #background {
    @media (min-width: ${smallWidth}) {
     display: none;
    }

    width: 100%;
    height: 40%;
    position: absolute;
    bottom: 30%;
    background: var(--color-black);
    opacity: .8;
  }
  
  #relative {
    position: relative;
    height: 100%;
  }
  
  #inlinePhilosophy {
    margin: 0 auto;
    position: absolute;
    top: 40%;
    font-size: 2.5rem;
    letter-spacing: 1rem;
    text-indent: 2rem;
  	color: #fffff0;
	  text-shadow:
		0 0.05em 0.08em #d0d0d0b1;
  }

  #flexLists {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 20px auto;
    padding: 0;
    width: 100%;

    @media (max-width: ${smallWidth}) {
      display: block;
    }
  }
`;

const TargetList = styled.li`
  font-size: 1.2rem;
  text-align: left;
  width: 20%;
  height: 30%;
  margin: 20px;
  padding: 20px;
  border: 1px solid #fff;
  border-radius: 10px;
  
  @media (max-width: ${smallWidth}) {
    display: flex;
    width: 80%;
    border: 0;
    border-radius: 0;
    margin: 0 20px;
    /* padding: 10px; */
    & + & {
      border-top: 1px solid #fff;
    }

    @media (max-width: ${mobileWidth}) {
      font-size: ${smallPSize};
    }
  }

  img {
    width: 50px;
    height: 50px;
    border: 1px #fff solid;
    border-radius: 50%;
    margin: 0 20px 10px;
    background-color: var(--color-pink);  

    @media (max-width: ${smallWidth}) {
      margin: 0 5px 0 0;
    }
  }

  .description {
    display: block;
  }
`;

type targetListsObj = {
  index: number;
  description: string;
  image: any;
}

const targetLists: targetListsObj[]= [
  {
    index: 1,
    description: "行っても変わらんと確信し選挙に行かない人材",
    image: icons.election,
  },
  {
    index: 2,
    description: "日常のストレスをSNSで発散する人材",
    image: icons.phone,
  },
  {
    index: 3,
    description: "老後の生活に憂いてFXとビットコインを始める人材",
    image: icons.bitcoin,
  },
  {
    index: 4,
    description: "金貸しに行って何も苦労なく生活をする人材",
    image: icons.atm,
  },
  {
    index: 5,
    description: "平和を唱え続ければ争いが無くなると信じる人材",
    image: icons.pigeon,
  },
  {
    index: 6,
    description: "非合理的な生活を追求するため、冠婚葬祭に行かない人材",
    image: icons.festival,
  },
  {
    index: 7,
    description: "完全に人望が喪失する処世術を会得した人材",
    image: icons.communication,
  },
]

const Content: React.FC = (): JSX.Element => {
  return(
    <PhilosophyContent>
      <p id='centerPhilosophy'>附和雷同</p>
      <div id='flex'>
        <div id='classroom'>
          <img src={images.chair} alt="教室" />
          <div id='background'>
            <div id='relative'>
              <p id='inlinePhilosophy'>附和雷同</p>
            </div>
          </div>
        </div>
        <p id='size'>
          倫理教育に関して{currentYear - 1915}年の歴史を持つ本学。教職員が一体となって学生運動、脱税、相場操縦、サボタージュ、賭博の支援などを手厚く行って参ります。<br />
          本学の教育理念である「附和雷同（ふわらいどう）」は、足並みを確実に揃え、少しでも異質だと認めれば集団で排除しようとする我が国の集団主義の行動原則に深く寄与し、お上に従順な人格形成を行っていく強い決意を表したものです。
        </p>
      </div>
      <h3>目指すべき人材</h3>
      <ul id='flexLists'>
        {
          targetLists.map(targetList => {
            return(
              <TargetList key={targetList.index}>
                <img src={targetList.image} alt="アイコンタグ" />
                <span className='description'>{targetList.description}</span>
              </TargetList>
            );
          })
        }
      </ul>
    </PhilosophyContent>
  );
}

export const Introduction: React.FC = (): JSX.Element => {
  return(
    <>
      <CommonContents
      title='本学の特色'
      subtitle='Introduction'
      children={<Content />}
      />
    </>
  );
}

export default Introduction;