import React, { useEffect } from 'react';
import { Gallery } from './Gallery';
import NewsBox from './news/News-box';
import Contents from './Contents';

const Home: React.FC = (): JSX.Element => {

  useEffect(() => {
    document.title = "根津増大学";
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return(
    <>
      <Gallery />
      <NewsBox />
      <Contents />
    </>
  )
}

export default Home;