import { RegisterResult } from './Result';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { CSSTransition } from "react-transition-group";
import { getCookieValue, subdomain } from "../../../components/Commmons";
import { ApplicationTransitionStyle, FormStyle, InputRadio, InputText, SelectStyle } from '../../../components/commons/form-styles';
import { LinearIndeterminate } from '../../../components/Material-ui';
import { Button } from '../../../components/commons/Button';

type BasicRegisterData = {
  name: string;
  furigana: string;
  sex: "男" | "女";
  selectYear: number;
  selectMonth: number;
  selectDate: number;
  prefecture: string;
  email: string;
  faculty: "法" | "経済" | "情報";
};

type AddTokenBasicRegisterData = {
  token: any;
  confirmEmail: string;
}& BasicRegisterData;

export type ReceivedRegisterData = {
  examNum: number;
} & BasicRegisterData;

type SendRegisterData = {
  confirmEmail: string;
} & BasicRegisterData;

const prefectures: string[] = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];

export const Register: React.FC = (): JSX.Element => {
  const [addressValidity, setAddressValidity] = useState<boolean>(false);
  const [receivedRegisterData, setReceivedRegisterData] = useState<ReceivedRegisterData>({
    examNum: 0,
    name: "",
    furigana: "",
    sex: "男",
    selectYear: 0,
    selectMonth: 0,
    selectDate: 0,
    prefecture: "",
    email: "",
    faculty: "法",
  });
  const [registerStatus, setRegisterStatus] = useState<boolean>(false);
  const [spinStatus, setSpinStatus] = useState<boolean>(false);
  const [errorStatus, setErrorStatus] = useState<boolean>(false);

  const ref = useRef<boolean>(true);  
  useEffect(() => {
    if (ref.current) {
      ref.current = false;
      return;
    }

    console.log(receivedRegisterData);
    setRegisterStatus(true);
  }, [receivedRegisterData]);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<SendRegisterData>();

  const sendForm = (inputValue: SendRegisterData) => {
    setSpinStatus(true);
    const addTokenBasicRegisterData: AddTokenBasicRegisterData = {
      token: getCookieValue("token"),
      ...inputValue
    }
    axios.post(
      `${subdomain}/register`,
      addTokenBasicRegisterData,
      { withCredentials: true }
    )
    .then((res: any) => {
      console.log(res);
      console.log("出願データ送信完了");
      console.log(res.data as ReceivedRegisterData);
      setReceivedRegisterData(res.data as ReceivedRegisterData);
    })
    .catch(error => {
      setTimeout(() => {
        console.error(error);
        console.log("データの送信に失敗しました。");
        setErrorStatus(true);
        setRegisterStatus(true);
      }, 5000);
    });
  }
  
  const onSubmit: SubmitHandler<SendRegisterData> = (data): any => {
    return new Promise((resolve, reject) => {
      // 入力された2つのメール文章の一致の確認
      if (data.email !== data.confirmEmail) {
        console.log("メールアドレスをもう一度入力してください");
        setAddressValidity(true);
        reject("送信失敗です");
        return;    
      }
      resolve("送信中です");  
    })
    .then((value) => {
      console.log(`${value}`);
      console.log(data);
      sendForm(data);
    })
    .catch((err) => {
      console.log(err);
    });
  }


  return (
    <ApplicationTransitionStyle>
      <FormStyle>
        <h1>インターネット出願</h1>
        <div className='area'>
          <CSSTransition
          classNames="form-fadeout"
          in={!registerStatus}
          timeout={700}
          unmountOnExit>
            <div>
              <p><span className='asterisk'>＊</span>印の項目は、必須入力です。</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <ul>
                  <div className='flexBox'>
                    <li>
                      <span className='label'>名前<span className='asterisk'>＊</span>:</span>
                      {errors?.name?.message === "" && <span className="errorMessage">未入力です</span>}
                      <InputText type="text" maxLength={50} pattern="[^\x20-\x7E]*" {...register("name", {required: true})} autoFocus />
                    </li>
                    <li>
                      <span className='label'>ふりがな<span className='asterisk'>＊</span>:</span>
                      {errors?.furigana?.message === "" && <span className="errorMessage">未入力です</span>}
                      <InputText type="text" maxLength={50} pattern='(?=.*?[\u3041-\u309F])[\u3041-\u309F\u30FC]*' {...register("furigana", {required: true})} />
                    </li>
                    <li>
                      <span className='label'>性別<span className='asterisk'>＊</span>:</span>
                      {errors?.sex?.message === "" && <span className="errorMessage">未選択です</span>}
                      <InputRadio>
                        <label className="ECM_RadioInput">
                          <input  {...register("sex", {required: true})} className="ECM_RadioInput-Input" type="radio" name="sex" value="男" />
                          <span className="ECM_RadioInput-DummyInput"></span>
                          <span className="ECM_RadioInput-LabelText">男</span>
                        </label>
                        <label className="ECM_RadioInput">
                          <input {...register("sex", {required: true})} className="ECM_RadioInput-Input" type="radio" name="sex" value="女" />
                          <span className="ECM_RadioInput-DummyInput"></span>
                          <span className="ECM_RadioInput-LabelText">女</span>
                        </label>
                      </InputRadio>
                    </li>
                  </div>
                  <div className='flexBox'>
                    <li>
                      <span className='label'>生年月日<span className='asterisk'>＊</span>:</span>
                      <SelectStyle>
                        <select {...register("selectYear")} className="select">
                          {(() => {
                            const yearsArray: any[] = [];
                            const possibleYear: any = new Date().getFullYear() - 16;

                            for (let i: number = 1900; i < possibleYear; i++) {
                              yearsArray.unshift(<option key={i}>{i}</option>);
                            }
                            return yearsArray;
                          })()}
                        </select><span className='endSpan'>年</span>
                        <select {...register("selectMonth")} className="select">
                          {(() => {
                              const monthArray: any[] = [];
                              for (let i: number = 1; i <= 12; i++) {
                                monthArray.push(<option key={i}>{i}</option>);
                              }
                              return monthArray;
                          })()}
                        </select><span className='endSpan'>月</span>
                        <select {...register("selectDate")} className="select">
                          {(() => {
                              const daysArray: any[] = [];
                              for (let i: number = 1; i <= 31; i++) {
                                daysArray.push(<option key={i}>{i}</option>);
                              }
                              return daysArray;
                          })()}
                        </select><span className='endSpan'>日</span>
                      </SelectStyle>
                    </li>
                    <li>
                      <span className='label'>都道府県名<span className='asterisk'>＊</span>:</span>
                      <SelectStyle>
                        <select {...register("prefecture")} className="select">
                          {prefectures.map((prefecture, index) => <option value={prefecture} key={index}>{prefecture}</option>)}
                        </select>
                      </SelectStyle>
                    </li>
                  </div>
                  <div className='flexBox'>
                    <li>
                      <span className='label'>メールアドレス:</span>
                      {addressValidity && <span className="errorMessage">確認してください</span>}
                        <InputText type="email" maxLength={250} {...register("email")} />
                    </li>

                    <li>
                      <span className='label'>メールアドレス再入力:</span>
                      {addressValidity && <><br /><span style={{height: "35px", position: "relative", color: "yellow", float: "right"}}>確認してください</span></>}
                      <InputText type="email" maxLength={250} {...register("confirmEmail")} />
                    </li>

                    <li>
                      <span className='label'>出願学部<span className='asterisk'>＊</span>:</span>
                      <SelectStyle>
                        <select {...register("faculty")} className="select">
                          <option value="9">法</option>
                          <option value="8">経済</option>
                          <option value="7">情報</option>
                        </select><span className='endSpan'>学部</span>
                      </SelectStyle>
                    </li>
                  </div>
                </ul>
              <div className='spin'>
                { spinStatus && LinearIndeterminate(({})) }
              </div>
              <Button
              style={{ display: "block", margin: "20px auto 0" }}
              title="出願"
              color="#F14646"
              width="60%"
              disabled={spinStatus}
              />
              
              </form>
            </div>
          </CSSTransition>
          <CSSTransition
          classNames="received-data-fadein"
          in={registerStatus || errorStatus}
          timeout={3000}
          unmountOnExit>
            <RegisterResult
            errorStatus={errorStatus}
            registerStatus={registerStatus}
            receivedRegisterData={receivedRegisterData}
            />
            {/* ここの動きが実行されない */}
          </CSSTransition>
        </div>
      </FormStyle>
    </ApplicationTransitionStyle>
  );
}