import React from 'react';
import CommonContents from '../../components/Common-page-layout';
import { images } from '../../components/commons/images';
import FacultyContentsLayout from '../../components/Faculty-contents';

const Economics: React.FC = (): JSX.Element => {
  return(
    <>
      <CommonContents
        title='経済学部'
        subtitle='Faculty of Economics'
        children={
          <FacultyContentsLayout
          theme='Moral Hazard'
          signature='ヘンリー・リーマン'
          image={images.graph}
          alt="株価"
          description='本学部では、住宅バブルを永遠だと錯覚し身の丈にあった生活をしない迷える子羊たちに住処を与える方法、債権を楽観志向の適当な格付けを行ってリスクを隠蔽し優良投資商品として売りつける方法、債務不履行の増加と住宅価格の頭打ちによる金融機関の経営破綻の方法、世界的な金融危機での過ごし方といった実践的な学びを提供します。'
        />}
      />
    </>
  );
}

export default Economics;