import React from 'react';
import CommonContents from '../../components/Common-page-layout';

const Content: React.FC = (): JSX.Element => {
  return(
    <>
      <p>本学では、来校に至るまでの交通手段が初心者にはキツすぎるのでオーキャンなるものはやっておりません。</p>
    </>
  );
}

const OpenCampus: React.FC = (): JSX.Element => {
  return(
    <>
      <CommonContents
      title='オープンキャンパス'
      subtitle='Open Campus'
      children={<Content />}
      />
    </>
  );
}

export default OpenCampus;