import React, { Suspense } from "react";
import CommonContents from "../../../components/Common-page-layout";

const Content: React.FC = (): JSX.Element => {

  const FetchNews = React.lazy(() => import("./Fetch-news"));

  return(
    <Suspense fallback={<p>Loading...</p>}>
      <FetchNews countGet={"999"}/>
    </Suspense>
  );
}

const Archives: React.FC = (): JSX.Element => {
  return(
    <CommonContents title="ニュース一覧" subtitle="Archives" children={<Content />} />
  );
}

export default Archives;