import Modal from './application/Modal';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CommonContents from '../../components/Common-page-layout';
import { Button } from '../../components/commons/Button';
import Table from '../../components/commons/Table';


type SchoolHistoriesProps = {
  td_1: JSX.Element,
  td_2: number,
  td_3: string,
  td_4: number,
  td_5: number,
  td_6: string
}[];

const thContents: string[] = [
  "学部",
  "定員",
  "教科",
  "試験時間",
  "配点",
  "備考",
];

const schoolHistories: SchoolHistoriesProps = [
  {
    td_1: <Link to={"/law"}>法学部</Link>,
    td_2: 120,
    td_3: "国語 英語",
    td_4: 100,
    td_5: 200,
    td_6: "ー",
  },
  {
    td_1: <Link to={"/economics"}>経済学部</Link>,
    td_2: 200,
    td_3: "英語",
    td_4: 100,
    td_5: 200,
    td_6: "ー",
  },
  {
    td_1: <Link to={"/infomatics"}>情報学部</Link>,
    td_2: 255,
    td_3: "数学 英語",
    td_4: 100,
    td_5: 200,
    td_6: "ー",
  },
];

const Content: React.FC = (): JSX.Element => {
  const [showApplication, setShowApplication] = useState<boolean>(false);

  return(
    <>
      <Modal
      showApplication={showApplication} setShowApplication={setShowApplication}
      />
      <p>
        一般選抜（全学部日程）では、全ての学部で選抜を実施します。<br />
        学部が指定する独自問題のみで受験することができます。また、すべての教科・科目において、マークシート方式（解答用紙1枚）となります。<br />
        学部間の併願は基本的にできませんが、試験日が異なれば併願は可能です。
      </p>
      <h3>一般選抜募集要項</h3>
      <Table th={thContents} td={schoolHistories}/>
      <h3>インターネット出願・出願状況の確認</h3>
      <p>一般選抜の出願方法はインターネット出願のみとなります。以下のボタンより出願及び状況の確認を行ってください。</p>
      <Button
      onClick={() => setShowApplication((prev) => !prev)}
      color="#F14646"
      title="インターネット出願・出願状況の確認"
      style={{display: "block", margin: "10vw auto 0"}}
      width="85%"
      />
    </>
  );
}

const Exam: React.FC = (): JSX.Element => {
  return(
    <>
      <CommonContents
      title="受験案内"
      subtitle="Exam"
      children={<Content />}
      />
    </>
  );
}

export default Exam;