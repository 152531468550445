import React from 'react';
import { smallWidth } from '../../components/Commmons';
import styled from 'styled-components';
import CommonContents from '../../components/Common-page-layout';

const MemoryDisplay = styled.div`
  width: 60%;
  height: 500%;
  margin: 50px auto;
  padding: 50px;
  border: solid 1px #fff;
  border-radius: 10px;
  
  @media (max-width: ${smallWidth}) {
    width: 75%;
    padding: 30px 20px;
  }
  
  .commentCompany {
    font-size: 1.5rem;
    color: var(--color-light-grey);
    margin: 0 0 50px;

    @media (max-width: ${smallWidth}) {
      font-size: 1rem;
      margin: 0 0 20px;
    }
  }

  .commentMessage {
    margin: 0;
  }
`;

const comments = [
  {
    company: "株式会社 暗黒 （2020年度 法学部卒）",
    message: "根津増大の教員の方々は非常に狡猾で、挨拶をしても返さず、一番前の座席に座っているだけで気味が悪い奴と烙印を押されて成績を付けてくれません。気分屋クソ教員が非常に多いので、間違ってブラック企業に入社しても「母校よりは天国だ」と思えるようになり、今の置かれた状況に対して高い幸福感を味わうことができます。精神病を患った同期が次々にいなくなっていますが、根津増大で鍛え上げられた鋼、いや、ダイヤのようなメンタルで無事に社内最年少で部長に昇進することができました (昇進すべき部下がそもそもいないのはあまり関係ありません)。\n 社会の荒波の発生源とも言える根津増大を卒業した暁には、どうしたら荒波を立てることが出来るのかといった、一歩先を行くクソ社会人となれます。受験生の皆さんはあまり情報をよく見ないで受験するようにしましょう。"
  },
  {
    company: "就職浪人 （2021年度 情報学部卒）",
    message: "授業中寝てても徹マンで賭けてましたって伝えればまた寝させてもらえますサイコー"
  }
];

const Content: React.FC = (): JSX.Element => {
  return(
    <>
    {
      comments.map(comment => {
        return(
          <MemoryDisplay>
            <h4 className='commentCompany'>{comment.company}</h4>
            <p className='commentMessage'>{comment.message}</p>
          </MemoryDisplay>
        )
      })
    }
    </>
  );
}

const Memories: React.FC = (): JSX.Element => {
  return(
    <>
      <CommonContents
      title='卒業生回顧録'
      subtitle='Memories'
      children={<Content />}
      />
    </>
  );
}

export default Memories;