import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import Header from './components/Header';
import App from './App';
import Footter from './components/Footter';
import { BrowserRouter } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';



console.log = () => {};
console.error = () => {};
console.debug = () => {};


const container: any = document.getElementById('root');
const root:any = createRoot(container);
root.render(
  <React.StrictMode>
    <ParallaxProvider>
      <BrowserRouter>
        <Header />
        <App />
        <Footter />
      </BrowserRouter>
    </ParallaxProvider>
  </React.StrictMode>,
);