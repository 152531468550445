import styled from "styled-components";
import { images } from "./commons/images";


export const subdomain = "https://api.netsuzo-u.com";
// export const subdomain = "";

export const middleWidth: string = "1140px";
export const smallWidth: string = "950px";
export const mobileWidth: string = "600px";
export const commonPSize: string = "1.3rem";
export const smallPSize: string = "0.9rem";
export const smallH1Size: string = "1.4rem";
export const commonLineHeight: string = "50px"
export const smallLineHeight: string = "1.7rem"

type NaviList = {
  naviId: number;
  title: string;
  url: string;
  subtitle: string;
  image: any;
}
  
type MenuList = {
  naviId: number;
  article: {
    title: string;
    subtitle: string;
    url: string;
    image: any;
  }[]; 
}

export const naviLists: NaviList[] = [
  {
    naviId: 0,
    title: '大学案内',
    url: '/univ-guide',
    subtitle: 'College Guidance',
    image: images.infomation
  },
  {
    naviId: 1,
    title: '学部',
    url: '/faculty-guide',
    subtitle: 'Faculties',
    image: images.study
  },
  {
    naviId: 2,
    title: '受験生の皆様へ',
    url: '/admission-guide',
    subtitle: 'Admission Guidance',
    image: images.note
  },
  {
    naviId: 3, title: '就職',
    url: '/career-guide',
    subtitle: 'Career',
    image: images.career
  },
  {
    naviId: 4, title: 'アクセス',
    url: '/access-guide',
    subtitle: 'Access',
    image: images.map
  },
];

export const menuLists: MenuList[] = [
  {
    naviId: 0,
    article: [
      {
        title: '本学の特色',
        subtitle: 'Introduction',
        url: '/introduction',
        image: images.library,
      },
      {
        title: '本学の歴史',
        subtitle: 'History',
        url: '/history',
        image: images.Yamada,
      },
      {
        title: '学長挨拶',
        subtitle: 'Message',
        url: '/message',
        image: images.campuslife,
      },
    ],
  },
  {
    naviId: 1,
    article: [
      {
        title: '法学部',
        subtitle: 'Law',
        url: '/law',
        image: images.law,
      },
      {
        title: '経済学部',
        subtitle: 'Economics',
        url: '/economics',
        image: images.graph,
      },
      {
        title: '情報学部',
        subtitle: 'Infomatics',
        url: '/infomatics',
        image: images.anonymous,
      },
    ],
  },
  {
    naviId: 2,
    article: [
      {
        title: 'オープンキャンパス',
        subtitle: 'Open-campus',
        url: '/oc',
        image: images.opencampus,
      },
      {
        title: '受験案内',
        subtitle: 'Exam',
        url: '/exam',
        image: images.exam,
      },
      // {
      //   title: '資料請求・問い合わせ',
      //   subtitle: 'Contact',
      //   url: '/contact',
      //   image: images.contact,
      // },
    ],
  },
  {
    naviId: 3,
    article: [
      {
        title: '就職先',
        subtitle: 'Career',
        url: '/career',
        image: images.work,
      },
      {
        title: '資格',
        subtitle: 'Qualification',
        url: '/qualification',
        image: images.qualification,
      },
      {
        title: '卒業生回顧録',
        subtitle: 'Memories',
        url: '/memories',
        image: images.memories,
      },
    ],
  },
  {
    naviId: 4,
    article: [
      {
        title: 'アクセスマップ',
        subtitle: 'Map',
        url: '/map',
        image: images.searoute,
      },
    ],
  },
];

export const currentYear: number = new Date().getFullYear();

export const getCookieValue = (key: string): string => {
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    var cookiesArray = cookie.split('='); 
    if (cookiesArray[0].trim() === key.trim()) { 
      return cookiesArray[1];
      // (key[0],value[1])
    }
  }
  return "";
}

export const ContentsFadeIn = styled.div`

  width: 100%;
  height: 100%;

  .readEnd-enter {
    opacity: 0;
    filter: blur(10px);
    transform: translateY(50px);
  }
  
  .readEnd-enter-active {
    opacity: 1;
    filter: blur(0px);
    transform: translateY(0);
    transition: all 0.9s;
  }
`;

export const OverlayPlate = styled.div`
  position: fixed;
  top: 0;
  z-index: 50;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
`;

export const OverlayTransitionStyle = styled.div`
  .overlay-enter {
    opacity: 0;
  }

  .overlay-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.5s;
  }

  .overlay-exit {
    opacity: 1;
  }

  .overlay-exit-active {
    opacity: 0;
    transition: all 0.5s;
  }
`;