import React from "react";
import { CSSTransition } from "react-transition-group";
import { middleWidth, mobileWidth, smallPSize } from "../../../components/Commmons";
import { FormTransitionStyle } from "./Modal-content";
import styled from "styled-components";
import { ReceivedRegisterData } from "./Register";
import { Button } from "../../../components/commons/Button";


// confirm

type ConfirmResultProps = {
  confirmErrorStatus: boolean;
  notFoundStatus: boolean;
  redirectPublication: () => void;
  redirectPermit: boolean;
  LinearIndeterminate: React.FC;
}

export const ConfirmResult: React.FC<ConfirmResultProps> = ({
  confirmErrorStatus,
  notFoundStatus,
  redirectPublication,
  redirectPermit,
  LinearIndeterminate
}): JSX.Element => {
  return (
    <div>
      {!confirmErrorStatus && !notFoundStatus ?
        <div>
          <p>出願済みです</p>
          <p>合否を確認する場合はボタンを押してください。</p>
          <div className='relative'>
            <FormTransitionStyle>
              <CSSTransition classNames="form-fadein" in={redirectPermit} timeout={3000} unmountOnExit>
                <div>
                  {redirectPermit ?
                    <div id='decision'>
                      <p style={{textAlign: "center"}}>合否を確認しています</p> 
                      <div style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "0"
                      }}>
                        {LinearIndeterminate(({}))}
                      </div>
                    </div>: null
                  }
                </div>
              </CSSTransition>
            </FormTransitionStyle>
            <Button
            width="50%"
            title="合否を確認"
            color="#F14646"
            onClick={redirectPublication}
            style={{
              display: "block",
              position: "absolute",
              bottom: "2rem",
              left: "25%",
            }}
            />
          </div>
        </div> : null}
      {confirmErrorStatus ?<p>送信に失敗しました。もう一度最初からやり直してください。</p>: null}
      {notFoundStatus ? <p>該当のデータが確認できません。<br />
        恐れ入りますが、出願時に入力されました情報をご確認ください。</p> : null}
    </div>
  );
}


// register

type RegisterResultProps = {
  errorStatus: boolean;
  registerStatus: boolean;
  receivedRegisterData: ReceivedRegisterData;
}

const ResultTable = styled.div`
  table {
    margin: 10px auto;
    width: 100%;
    font-size: 1rem;

    @media (max-width: ${middleWidth}) {
      margin: 20px auto;
    }

    @media (max-width: ${mobileWidth}) {
      font-size: ${smallPSize};
    }
  }

  th {
    padding: 10px 0;
  }

  td {
    text-align: center;
    padding: 13px 0;
    border-top: 1px solid #fff;
  }
  
  .lineName {
    border-right: 2px dotted #fff;
  }
`;

export const RegisterResult: React.FC<RegisterResultProps> = ({
  errorStatus,
  registerStatus,
  receivedRegisterData
}) => {
  return(
    <>
      <ResultTable>
      {errorStatus ?
      <p>送信に失敗しました。もう一度最初からやり直してください。</p>
      :
      <p>出願が正常に完了しました。送付されたメールをご確認ください。メールを入力されなかった方は受験番号を必ずお控え下さい。</p>}
        {registerStatus && !errorStatus ? <table>
          <thead>
            <tr>
              <th className='lineName'>項目</th>
              <th>内容</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='lineName'>受験番号</td>
              <td>{receivedRegisterData.examNum}</td>
            </tr>
            <tr>
              <td className='lineName'>受験学部</td>
              <td>{receivedRegisterData.faculty}</td>
            </tr>
            <tr>
              <td className='lineName'>受験者氏名</td>
              <td>{receivedRegisterData.name}</td>
            </tr>
            <tr>
              <td className='lineName'>ふりがな</td>
              <td>{receivedRegisterData.furigana}</td>
            </tr>
            <tr>
              <td className='lineName'>性別</td>
              <td>{receivedRegisterData.sex}</td>
            </tr>
            <tr>
              <td className='lineName'>誕生日</td>
              <td>{receivedRegisterData.selectYear}月{receivedRegisterData.selectMonth}月{receivedRegisterData.selectDate}日</td>
            </tr>
            <tr>
              <td className='lineName'>都道府県名</td>
              <td>{receivedRegisterData.prefecture}</td>
            </tr>
            <tr>
              <td className='lineName'>メールアドレス</td>
              <td>{receivedRegisterData.email}</td>
            </tr>
          </tbody>
        </table> : null}
      </ResultTable>
    </>
  );
}
    