import React from 'react';
import styled from 'styled-components';
import { naviLists, smallWidth, mobileWidth } from './Commmons';
import { Link } from 'react-router-dom';
import { ShowMenuLists } from './Slide-menu';
import { icons } from './commons/icons';


const Foot = styled.footer`
  background-color:  var(--color-blue);
  padding: 50px 0;
  margin: 0;
  margin-top: 100px;
  text-align: center;
  
  #twitter {
    display: inline-block;
    img{
      margin-top: 10px;
    }
  }
  
  #category {
    display: flex;
    text-align: left;
    justify-content: space-evenly;
    
    @media (max-width: ${smallWidth}) {
      display: none;
    }
  }

  .footterLink{
    color: #DCE0D9;
    transition: .5s all;
  }
  
  .footterLink:hover{
    color: #fff;
    transition: .5s;
  }
  
  .footterLink::after{
    background-color: tomato;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    transition: .5s all;
    width: 0;
  }
  
  .footterLink:hover::after{
    width: 100%;
  }

  .verticalLine {
    height: 150px;
  }

  .verticalLine + .verticalLine {
    border-left: solid 2px #fff;
  }

  .footerGuide {
    transition: .3s all;
  }

  .footerGuide:hover {
    letter-spacing: .2rem;
    transition: .3s all;
  }

  .center {
    text-align: center;
  }

  .copyright {
    margin-top: 100px;

    @media (max-width: ${mobileWidth}) {
      margin-top: 50px;
    }
  }
`;

const Footter: React.FC = (): JSX.Element => {

  let ulArray: JSX.Element[] = [];

  for (let i = 0; i < naviLists.length; i++) {
    ulArray.push(
      <ul className='verticalLine' key={i}>
        <h4>
          <Link to={naviLists[i].url} className="footerGuide">
            {naviLists[i].title}
          </Link>
        </h4>
        <ShowMenuLists num={i}/>
      </ul>
    );
  }
  

  return(
    <Foot>
      <div id='category'>
        {ulArray}
      </div>
      <div id='twitter'>
        <a href="https://twitter.com/netsuzo_u" target={'_blank'}>
          <img src={icons.twitter} alt="ツイッターのリンク" width={"60vw"} />
        </a>
      </div>
      <div className='copyright'>
        <p className='center'>© 2023 Netsuzo University All Rights Reserved.</p>
        <p className='center'>根津増大学 〒100-2222 東京都小笠原村中ノ鳥島（無番地）</p>
      </div>
    </Foot>
  );
}

export default Footter;