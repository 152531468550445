import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';

export const LinearIndeterminate: React.FC = (): JSX.Element => {
  return (
    <Box sx={{ width: '60%' }}>
      <LinearProgress />
    </Box>
  );
}