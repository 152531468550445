import React from "react";
import GuideLayout from "../../components/Guide-layout";

const FacultyGuide: React.FC = (): JSX.Element=> {
  return(
    <>
      <GuideLayout
      title="学部"
      description="都会の喧騒から離れた中ノ鳥島で学ぶ、充実した3つの学部"
      menuListNaviId={1}
      />
    </>
  );
}

export default FacultyGuide;