import React from "react";
import GuideLayout from "../../components/Guide-layout";

const CareerGuide: React.FC = (): JSX.Element=> {
  return(
    <>
      <GuideLayout
      title="就職"
      description="本学の教育課程を修了した卒業生たちの歩み"
      menuListNaviId={3}
      />
    </>
  );
}

export default CareerGuide;