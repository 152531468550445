import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { middleWidth, mobileWidth, smallPSize, smallWidth } from '../../components/Commmons';
import { useInView } from 'react-intersection-observer';
import { images } from '../../components/commons/images';

const TransitionTile = styled.div`
  width: 100vw;

  .show-enter {
    opacity: 0;
    transform: translateY(1000px);
  }
  
  .show-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: transform 1s, opacity 1s;
  }

  #writer {
    margin: 80px 0 0;
    padding: 50px;
    font-size: 2rem;
    text-align: right;

    @media (max-width: ${smallWidth}) {
      font-size: 1rem;
    }
  }
`;

const MotionTileContent = styled.div`
  width: 100%;
  height: 100vh;
  background: var(--color-black);
  
  @media (max-width: ${smallWidth}) {
    padding: 30px 0px;
  }
  
  .flex {
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 100;
    align-items: center;

    @media (max-width: ${smallWidth}) {
      justify-content: space-evenly; 
    }
  }
  
  .flex.culumnReverse {
    @media (max-width: ${smallWidth}) {
      flex-direction: column-reverse;
    }
  }

  .flex.culumn {
    @media (max-width: ${smallWidth}) {
      flex-direction: column;
      margin: 20px 0;
    }
  }
  
  p {
    font-size: 2rem;
    line-height: 60px;
    padding: 0 20px;

    @media (max-width: ${middleWidth}) {
      font-size: 1.5rem;
      line-height: 50px;
    }

    @media (max-width: ${smallWidth}) {
      font-size: ${smallPSize};
      font-weight: bold;
    }
  }
    
  img {
    height: 40vw;
    border-radius: 10px;

    @media (max-width: ${mobileWidth}) {
      width: 90%;
      height: 40%;
    }
  }
`;

export const MotionTile: React.FC = (): JSX.Element =>{
  const {ref: ref1, inView: toShowStatus1} = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  const {ref: ref2, inView: toShowStatus2} = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  const {ref: ref3, inView: toShowStatus3} = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  const {ref: ref4, inView: toShowStatus4} = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  const {ref: ref5, inView: toShowStatus5} = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  const {ref: ref6, inView: toShowStatus6} = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  return(
    <TransitionTile>
      <MotionTileContent  ref={ref1}>
        <CSSTransition
        classNames="show"
        in={toShowStatus1}
        timeout={1000}
        unmountOnExit>
          <div className='flex culumnReverse'>
            <p>
              インターネット上は情報が玉石混交としていて、何が正しいのか一瞬で判断するのは非常に難しいです。ただ一つ言えるのはこの大学のサイトは完全に”嘘”だと言うことです。
            </p>
            <img src={images.fakeNews} alt="description" />
          </div>
        </CSSTransition>
      </MotionTileContent>
      <MotionTileContent ref={ref2}>
        <CSSTransition
        classNames="show"
        in={toShowStatus2}
        timeout={1000}
        unmountOnExit>
          <div className='flex culumn'>
            <img src={images.understanding} alt="description" />
            <p>
              インターネットが普及し始めた当時、人間が様々な情報に触れることで相互理解が進み、世の中から戦争がなくなるかもしれないという希望がありました。
            </p>
          </div>
        </CSSTransition>
      </MotionTileContent> 
      <MotionTileContent ref={ref3}>
        <CSSTransition
        classNames="show"
        in={toShowStatus3}
        timeout={1000}
        unmountOnExit>
          <div className='flex culumnReverse'>
            <p>
              しかし、どうでしょう。実際のところそんなのはただのユートピアにしか過ぎません。現実社会だったら絶対に交わらないような人々がインターネット上で巡り合ってしまい、価値観のギャップから相互理解どころか罵詈雑言が飛び交ってしまう始末。このサイトだって作者自身ネタだと思ってるものの、このページを見て怒りが沸点に達してしまう人もいるかも知れません。
            </p>
            <img src={images.fire} alt="description" />
          </div>
        </CSSTransition>
      </MotionTileContent>
      <MotionTileContent ref={ref4}>
        <CSSTransition
        classNames="show"
        in={toShowStatus4}
        timeout={1000}
        unmountOnExit>
          <div className="flex culumn">
            <img src={images.people} alt="description" />
            <p>
              インターネットがいくら普及したとしても、人間のネットリテラシーが年々向上しているとはお世辞にも言えません。逆にリテラシーが低い、そもそもわからない人にまで普及してしまったとも考えられます。
            </p>
          </div>
        </CSSTransition>
      </MotionTileContent>
      <MotionTileContent ref={ref5}>
        <CSSTransition
        classNames="show"
        in={toShowStatus5}
        timeout={1000}
        unmountOnExit>
        <div className="flex culumnReverse">
          <p>
            もし、インターネット上でなにかイライラしてしまったら一旦深呼吸をしましょう。できるならそこから離れましょう。もし相手が生の人間だったら感情はそこまで高ぶらないはずです。所詮あなたが見ているのは、目の前の液晶ディスプレイに映された無機質な文章や図表です。そこから感情や空気感などを正確に感じ取ることはできません。淡々と描画されたものに対してあなた自身の独善的な感情が先行してしまい、それが結果的に負の行動をインターネット上に残してしまうのです。
          </p>
          <img src={images.relux} alt="description" />
        </div>
        </CSSTransition>
      </MotionTileContent>
      <MotionTileContent ref={ref6}>
        <CSSTransition
        classNames="show"
        in={toShowStatus6}
        timeout={1000}
        unmountOnExit>
          <div className="flex culumn">
          <img src={images.marbles} alt="description" />
          <p>
            このサイトはフィクションです。こんな暇な作者の自己満足コンテンツにお付き合いいただき感謝申し上げます。終わりなき荒れ狂うインターネットの中であなたが少しでも気休めになれば幸甚です。
          </p>
          </div>
        </CSSTransition>
      </MotionTileContent>
    </TransitionTile>
  );
}