import React from 'react';
import CommonContents from '../../components/Common-page-layout';

const Content: React.FC = (): JSX.Element => {
  return(
    <></>
  );
}

const Contact: React.FC = (): JSX.Element => {
  return(
    <>
      <CommonContents
      title='資料請求・問い合わせ'
      subtitle='Contact'
      children={<Content />}
      />
    </>
  );
}

export default Contact;