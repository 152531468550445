import React from 'react';
import CommonContents from '../../components/Common-page-layout';

const Content: React.FC = (): JSX.Element => {
  return(
    <>
      <p>卒業生全員有名フリーランスとして活躍中！</p>
    </>
  );
}

const Career: React.FC = (): JSX.Element => {
  return(
    <>
      <CommonContents
      title='就職先'
      subtitle='Career'
      children={<Content />}
      />
    </>
  );
}

export default Career;