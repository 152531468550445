import React from 'react';
import styled from 'styled-components';
import { commonLineHeight, smallLineHeight, smallWidth } from '../../components/Commmons';
import CommonContents from '../../components/Common-page-layout';
import { images } from '../../components/commons/images';
import Table from '../../components/commons/Table';


const MapLayout = styled.div`
  #picture {
    display: flex;
    justify-content: center;
    width: 100%;

    @media (max-width: ${smallWidth}) {
      display: block;
    }
  }

  .address > p {
    text-align: center;
    font-size: 1.5rem;
    line-height: ${commonLineHeight};
    
    @media (max-width: ${smallWidth}) {
      font-size: 1rem;
      line-height: ${smallLineHeight};
    }
  }

  #shipImage {
    width: 500px;
    display: block;
    margin-right: 20px;
    border-radius: 10px;

    @media (max-width: ${smallWidth}) {
      width: 90vw;
      margin: 20px auto;
    }
  }
`;

const GoogleMap = styled.iframe`
  width: 500px;
  border: 1px solid var(--color-black);
  border-radius: 10px;
  display: block;

  @media (max-width: ${smallWidth}) {
    width: 90vw;
    height: 40vw;
    margin: auto;
  }
`;

type timelineProps = {
  td_1: string,
  td_2: string,
  td_3: string,
  td_4: string,
  td_5: string
}[];

const thContents: string[] = [
  "日付",
  "根本港発",
  "中ノ鳥島着",
  "中ノ鳥島発",
  "根本港着",
];

const tdContents: timelineProps = [
  {
    td_1: "日",
    td_2: "ー",
    td_3: "19:00",
    td_4: "7:00",
    td_5: "ー",
  },
  {
    td_1: "月",
    td_2: "7:00",
    td_3: "ー",
    td_4: "7:00",
    td_5: "ー",
  },
  {
    td_1: "火",
    td_2: "ー",
    td_3: "ー",
    td_4: "ー",
    td_5: "ー",
  },
  {
    td_1: "水",
    td_2: "7:00",
    td_3: "ー",
    td_4: "ー",
    td_5: "ー",
  },
  {
    td_1: "木",
    td_2: "ー",
    td_3: "ー",
    td_4: "ー",
    td_5: "ー",
  },
  {
    td_1: "金",
    td_2: "ー",
    td_3: "ー",
    td_4: "ー",
    td_5: "19:00",
  },
  {
    td_1: "土",
    td_2: "ー",
    td_3: "ー",
    td_4: "ー",
    td_5: "19:00",
  }
];
const emergencyTdContents: timelineProps = [
  {
    td_1: "日",
    td_2: "未定",
    td_3: "未定",
    td_4: "未定",
    td_5: "未定",
  },
  {
    td_1: "月",
    td_2: "未定",
    td_3: "未定",
    td_4: "未定",
    td_5: "未定",
  },
  {
    td_1: "火",
    td_2: "未定",
    td_3: "未定",
    td_4: "未定",
    td_5: "未定",
  },
  {
    td_1: "水",
    td_2: "未定",
    td_3: "未定",
    td_4: "未定",
    td_5: "未定",
  },
  {
    td_1: "木",
    td_2: "未定",
    td_3: "未定",
    td_4: "未定",
    td_5: "未定",
  },
  {
    td_1: "金",
    td_2: "未定",
    td_3: "未定",
    td_4: "未定",
    td_5: "未定",
  },
  {
    td_1: "土",
    td_2: "未定",
    td_3: "未定",
    td_4: "未定",
    td_5: "未定",
  }
];

const Content: React.FC<{emergency: boolean}> = (props): JSX.Element => {
  return(
    <MapLayout>
      <h3>中ノ鳥島キャンパス</h3>
      <p>千葉県南房総市根本港より中ノ鳥島キャンパス直行船便が運行しております。移動所要時間は片道4～5日間程かかります。十分に余裕を持ってご来学ください。</p>
      <div className='address'>
        <p>〒100-2222</p>
        <p>東京都小笠原村中ノ鳥島（無番地）</p>
      </div>
      <div id='picture'>
        <img id='shipImage' src={images.access} alt="船" />
        <GoogleMap src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21046937.963551372!2d139.6069890117878!3d38.257869714606755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9cfe8252f051a64f!2zMzDCsDA1JzAwLjAiTiAxNTTCsDAyJzAwLjAiRQ!5e0!3m2!1sja!2sjp!4v1648790808384!5m2!1sja!2sjp" loading="lazy" />
      </div>
      <h3>定期便時刻表</h3>
      {
        props.emergency ?
        <>
          <p>只今、すべての便で欠航しております。新しく情報が入り次第改めてお知らせいたします。
          </p>
          <Table th={thContents} td={emergencyTdContents}/>
        </>:
        <>
          <p>台風等の自然災害により、船の発着が変更となることがございます。その際は改めてお知らせいたします。</p>
          <Table th={thContents} td={tdContents}/>
        </>
      }

    </MapLayout>
  );
}

const Map: React.FC = (): JSX.Element => {
  return(
    <>
      <CommonContents
      title='アクセス'
      subtitle='Access Map'
      // 船を欠航させる時はtrueにする
      children={<Content emergency={false} />}
      />
    </>
  );
}

export default Map;