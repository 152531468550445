import React from "react";
import styled from "styled-components";
import { middleWidth, mobileWidth, smallWidth } from "../../components/Commmons";

type CommonContentsProps = {
  title: string,
  children: JSX.Element,
}

const Layout = styled.div`
  margin: 10% 5% 5%;
  background: var(--color-background);

  @media (max-width: ${smallWidth}) {
    margin: 20% 0;
  }

  h1 {
    font-size: 4rem;
    text-align: center;

    @media (max-width: ${mobileWidth}) {
      font-size: 2rem;
    }
  }
  
  h1::after,
  h1::before
  {
    background-color: var(--color-light-blue);
    width: 30%;
    height: 1px;
    content: "";
    margin: 20px;
    display: inline-block;
    position: relative;
    vertical-align: middle;

    @media (max-width: ${middleWidth}) {
      width: 10%;
    }
  }
`;

const CommonHome: React.FC<CommonContentsProps> = (props): JSX.Element => {
  return(
    <Layout>
      <h1>{props.title}</h1>
      {props.children}
    </Layout>
  );
}

export default CommonHome;